.verification {

  > header {
    margin-bottom: 14px;

    > span, i {
      display: block;
      font-size: 28px;
      font-weight: 400;
    }
  }

  .dec {

    p {
      margin-bottom: 12px;
      font-weight: 400;
      font-size: 16px;
    }
  }
}

@media (max-width: 992px) {
  .verification {
    > header, .dec {
      padding: 0 20px;
    }

    > header {
      padding-top: 20px;
    }
  }
}
