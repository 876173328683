@import "color";

html, body {
  width: 100%;
  height: 100%;
  background-color: #F1F1F1;
  font-family: @font-family;
  color: #000000;
  line-height: 1.5;
  overflow-x: hidden;
}

.container {
  max-width: 1170px;
  margin: 0 auto;
}

.logo_icon {
  width: 97px;
}

.user-icon {
  position: relative;
  top: 20px;
  display: inline-block;
  width: 36px;
  height: 36px;
  background: rgb(52, 52, 52);
  border: 1px solid rgb(112, 112, 112);
  border-radius: 50%;
  text-align: center;
  padding-top: 6px;
  margin: 0 16px 0 20px;

  img {
    width: 18px;
  }
}

@media (max-width: 992px) {
  .logo_icon {
    width: 70px;
  }
}

.logo {
  width: 97px;
}

.btn-warning {
  display: inline-block;
  padding: 0 22px;
  height: 35px;
  line-height: 35px;
  left: 1088px;
  top: 422px;
  background: rgb(108, 117, 125);
  border-radius: 5px;
  font-family: @font-family;
  font-size: 15px;
  color: #ffffff;
  font-weight: 400;
  border: none;
  cursor: pointer;
}

.btn-complete {
  padding: 0 15px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border: 1px solid #28A745;
  border-radius: 5px;
  color: #28A745;
  display: inline-block;
  font-weight: 500;
}

.btn-pending {
  padding: 0 15px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border: 1px solid #777777;
  color: #777777;
  border-radius: 5px;
  display: inline-block;
  font-weight: 500;
}

.btn-close {
  padding: 0 15px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border: 1px solid #ba203f;
  border-radius: 5px;
  color: #ba203f;
  display: inline-block;
  font-weight: 500;
}

.text-primary {
  color: @primary-color !important;
}

.pointer {
  cursor: pointer;
}

.edit {
  color: @primary-color !important;
  cursor: pointer;
  font-size: 16px;
}

.p-items {
  position: relative;
  padding-left: 14px;
  font-size: 16px;
  font-weight: 400;

  &:before {
    left: 0;
    //top: -12px;
    position: absolute;
    display: inline-block;
    content: '-';
    color: @text-color;
    margin-right: 6px;
  }
}

.show {
  display: block;
}

.hide {
  display: none;
}

.break-word {
  word-wrap: break-word;
}

.pa-t-28 {
  padding-top: 28px;
}

.mar-t-40 {
  margin-top: 40px;
}

.mar-b-10 {
  margin-bottom: 10px;
}

.mar-b-14 {
  margin-bottom: 14px;
}

.mar-b-16 {
  margin-bottom: 16px;
}

.mar-b-26 {
  margin-bottom: 26px;
}

.mar-b-32 {
  margin-bottom: 32px;
}

.mar-b-40 {
  margin-bottom: 40px;
}

.min-height-338 {
  min-height: 338px;
}

.bg-white {
  background-color: #ffffff;
}

.page-title {
  font-size: 36px;
  font-weight: 500;
  margin-bottom: 60px;

  .customer-id-wrap {
    position: relative;
    top: 16px;

    .customer-id-title {
      font-size: 13px;
      color: @text-color2;
      margin-right: 10px;
    }
  }
}

@media (max-width: 992px) {
  .page-title {
    padding: 20px;
    margin-bottom: 20px;

    .customer-id-wrap {
      display: none;
    }
  }
}

.backBtn {
  img {
    width: 13px;
    position: relative;
    top: 7px;
  }

  span {
    margin-left: 10px;
  }
}

.table-wrap {

  > header {
    padding: 0 20px;
    margin-bottom: 6px;

    .title {
      font-size: 14px;
      font-weight: 500;
    }
  }

  > ul {
    border: 1px solid #CDCDCD;

    li {
      border-bottom: 1px solid #CDCDCD;
      padding: 14px 20px 16px 20px;

      &:nth-last-of-type(1) {
        border-bottom: none;
      }

      span {
        font-size: 16px;
      }

      .action {
        cursor: pointer;
        color: @primary-color;
      }

      &.beforeBar {
        position: relative;
        padding-left: 44px;

        &:before {
          content: "";
          display: inline-block;
          position: absolute;
          width: 20px;
          height: calc(100% - 16px);
          left: 0;
          top: 16px;
          background: rgb(22, 129, 229);
        }
      }

      &.timeBar {
        position: relative;

        &:hover {
          .news-title {
            > span {
              color: @primary-color;
            }
          }
        }

        .time {
          position: absolute;
          width: 60px;
          height: 60px;
          background: @text-color2;
          top: 0;
          left: 0;
          text-align: center;

          > span {
            display: block;
            font-size: 16px;
            color: #ffffff;
          }

          > span:nth-of-type(1) {
            padding-top: 4px;
            padding-left: 2px;
          }

          > span:nth-of-type(2) {
            padding-left: 4px;
          }
        }

        .news-title {
          padding-left: 60px;
          min-height: 40px;
        }
      }

      .posted {
        font-size: 14px;
        font-weight: 500;
        color: @text-color2;
        margin-right: 10px;
      }
    }
  }

  .M-pagination {
    padding: 20px 0;
  }
}

@media (max-width: 992px) {
  .table-wrap {
    ul {
      border-left: none;
      border-right: none;


      .item {
        margin-bottom: 6px;

        &:nth-last-of-type(1) {
          margin-bottom: 0;
        }
      }

      .action-wrap {
        text-align: right;
      }
    }
  }
}

@media (min-width: 992px) {
  .table-wrap {
    ul {
      .timeBar {
        .date {
          padding-left: 60px;
        }
      }
    }
  }
}

.lists-account {
  margin-bottom: 32px;
  border: 1px solid #CDCDCD;

  li {
    padding: 20px;
    border-bottom: 1px solid #CDCDCD;

    &:nth-last-of-type(1) {
      border-bottom: none;
    }

    .img-wrap {
      display: inline-block;
      min-width: 48px;
      text-align: center;
      margin-right: 32px;
    }

    .titles {
      font-size: 22px;
      position: relative;
      top: 11px;
    }

    .btn-wraps-account {
      position: relative;
      top: 2px;

      .M-btn-42 {
        float: right;
      }
    }
  }
}

@media (max-width: 992px) {
  .lists-account {
    border-left: none;
    border-right: none;

    li {
      .btn-wraps-account {
        margin-top: 10px;
        top: 0;

        .M-btn-42 {
          width: 100%;
        }
      }

      .img-wrap {
        margin-right: 16px;
      }

      .titles {
        font-size: 19px;
      }
    }
  }
}

.des-list {
  li {
    margin-bottom: 16px;

    .title, .text {
      display: block;
    }

    .title {
      font-size: 13px;
      font-weight: 500;
      margin-bottom: 6px;
      color: @text-color2;
    }

    .text {
      font-size: 16px;
    }
  }
}

.block-line {
  padding: 20px;
  border: 1px solid #CDCDCD;
}

@media (max-width: 992px) {
  .block-line {
    border-left: none;
    border-right: none;
  }
}

.card-no-header {
  border: 1px solid #CDCDCD;
  padding: 32px;
}

@media (max-width: 992px) {
  .card-no-header {
    padding: 20px;
  }
}

@media (max-width: 992px) {
  .news-detail-header {
    padding: 16px 20px 0 20px;
  }
}

.btn-group {
  text-align: center;
}

.ques-label {
  display: inline-block;
  font-weight: 500;
  font-size: 16px;
  color: @text-color2;
  margin-bottom: 4px;
}

.getCodeItem {
  position: relative;

  .getCodeButton {
    position: absolute;
    top: 26px;
    right: 0;
    border-bottom: 1px solid #FFFFFF;

    .btn-warning {
      min-width: 82px;
      text-align: center;
    }
  }
}

.localLang {
  img {
    width: 20px;
  }

  .border {
    border: 1px solid #ccc;
  }

  span {
    position: relative;
    top: -3px;
    margin-left: 10px;
  }
}

.font-size-16 {
  font-size: 16px !important;
}

.font-size-18 {
  font-size: 18px !important;
}

.font-size-22 {
  font-size: 22px !important;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-400 {
  font-weight: 400;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-color {
  color: @text-color;
}

.link {
  cursor: pointer;

  &:hover {
    color: @primary-color;
  }
}

.max-width-800 {
  max-width: 800px;
  margin: 0 auto;
}

.min-width-180 {
  min-width: 180px !important;
}

.top-12 {
  position: relative;
  top: 12px;
}

.pa-32 {
  padding: 32px !important;
}

.line-24 {
  line-height: 24px;
}

.disabled {
  cursor: not-allowed;
}














