body,
div,
ul,
ol,
li,
h1,
h2,
h3,
h4,
p,
section,
nav,
a,
tr,
th,
td,
input {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 14px;
}
img {
  border: none;
  vertical-align: top;
}
ul {
  list-style: none;
}
a {
  text-decoration: none;
}
i {
  font-style: normal;
}
.clearfix {
  zoom: 1;
}
.clearfix:after {
  content: ".";
  width: 0;
  height: 0;
  visibility: hidden;
  display: block;
  clear: both;
  overflow: hidden;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
table {
  border-collapse: collapse !important;
  /*table-layout: fixed;*/
}
html,
body {
  width: 100%;
  height: 100%;
  background-color: #F1F1F1;
  font-family: "Montserrat", Hiragino Kaku Gothic Pro, Meiryo, sans-serif;
  color: #000000;
  line-height: 1.5;
  overflow-x: hidden;
}
.container {
  max-width: 1170px;
  margin: 0 auto;
}
.logo_icon {
  width: 97px;
}
.user-icon {
  position: relative;
  top: 20px;
  display: inline-block;
  width: 36px;
  height: 36px;
  background: #343434;
  border: 1px solid #707070;
  border-radius: 50%;
  text-align: center;
  padding-top: 6px;
  margin: 0 16px 0 20px;
}
.user-icon img {
  width: 18px;
}
@media (max-width: 992px) {
  .logo_icon {
    width: 70px;
  }
}
.logo {
  width: 97px;
}
.btn-warning {
  display: inline-block;
  padding: 0 22px;
  height: 35px;
  line-height: 35px;
  left: 1088px;
  top: 422px;
  background: #6c757d;
  border-radius: 5px;
  font-family: "Montserrat", Hiragino Kaku Gothic Pro, Meiryo, sans-serif;
  font-size: 15px;
  color: #ffffff;
  font-weight: 400;
  border: none;
  cursor: pointer;
}
.btn-complete {
  padding: 0 15px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border: 1px solid #28A745;
  border-radius: 5px;
  color: #28A745;
  display: inline-block;
  font-weight: 500;
}
.btn-pending {
  padding: 0 15px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border: 1px solid #777777;
  color: #777777;
  border-radius: 5px;
  display: inline-block;
  font-weight: 500;
}
.btn-close {
  padding: 0 15px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border: 1px solid #ba203f;
  border-radius: 5px;
  color: #ba203f;
  display: inline-block;
  font-weight: 500;
}
.text-primary {
  color: #1681E5 !important;
}
.pointer {
  cursor: pointer;
}
.edit {
  color: #1681E5 !important;
  cursor: pointer;
  font-size: 16px;
}
.p-items {
  position: relative;
  padding-left: 14px;
  font-size: 16px;
  font-weight: 400;
}
.p-items:before {
  left: 0;
  position: absolute;
  display: inline-block;
  content: '-';
  color: #000000;
  margin-right: 6px;
}
.show {
  display: block;
}
.hide {
  display: none;
}
.break-word {
  word-wrap: break-word;
}
.pa-t-28 {
  padding-top: 28px;
}
.mar-t-40 {
  margin-top: 40px;
}
.mar-b-10 {
  margin-bottom: 10px;
}
.mar-b-14 {
  margin-bottom: 14px;
}
.mar-b-16 {
  margin-bottom: 16px;
}
.mar-b-26 {
  margin-bottom: 26px;
}
.mar-b-32 {
  margin-bottom: 32px;
}
.mar-b-40 {
  margin-bottom: 40px;
}
.min-height-338 {
  min-height: 338px;
}
.bg-white {
  background-color: #ffffff;
}
.page-title {
  font-size: 36px;
  font-weight: 500;
  margin-bottom: 60px;
}
.page-title .customer-id-wrap {
  position: relative;
  top: 16px;
}
.page-title .customer-id-wrap .customer-id-title {
  font-size: 13px;
  color: #777777;
  margin-right: 10px;
}
@media (max-width: 992px) {
  .page-title {
    padding: 20px;
    margin-bottom: 20px;
  }
  .page-title .customer-id-wrap {
    display: none;
  }
}
.backBtn img {
  width: 13px;
  position: relative;
  top: 7px;
}
.backBtn span {
  margin-left: 10px;
}
.table-wrap > header {
  padding: 0 20px;
  margin-bottom: 6px;
}
.table-wrap > header .title {
  font-size: 14px;
  font-weight: 500;
}
.table-wrap > ul {
  border: 1px solid #CDCDCD;
}
.table-wrap > ul li {
  border-bottom: 1px solid #CDCDCD;
  padding: 14px 20px 16px 20px;
}
.table-wrap > ul li:nth-last-of-type(1) {
  border-bottom: none;
}
.table-wrap > ul li span {
  font-size: 16px;
}
.table-wrap > ul li .action {
  cursor: pointer;
  color: #1681E5;
}
.table-wrap > ul li.beforeBar {
  position: relative;
  padding-left: 44px;
}
.table-wrap > ul li.beforeBar:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 20px;
  height: calc(100% - 16px);
  left: 0;
  top: 16px;
  background: #1681e5;
}
.table-wrap > ul li.timeBar {
  position: relative;
}
.table-wrap > ul li.timeBar:hover .news-title > span {
  color: #1681E5;
}
.table-wrap > ul li.timeBar .time {
  position: absolute;
  width: 60px;
  height: 60px;
  background: #777777;
  top: 0;
  left: 0;
  text-align: center;
}
.table-wrap > ul li.timeBar .time > span {
  display: block;
  font-size: 16px;
  color: #ffffff;
}
.table-wrap > ul li.timeBar .time > span:nth-of-type(1) {
  padding-top: 4px;
  padding-left: 2px;
}
.table-wrap > ul li.timeBar .time > span:nth-of-type(2) {
  padding-left: 4px;
}
.table-wrap > ul li.timeBar .news-title {
  padding-left: 60px;
  min-height: 40px;
}
.table-wrap > ul li .posted {
  font-size: 14px;
  font-weight: 500;
  color: #777777;
  margin-right: 10px;
}
.table-wrap .M-pagination {
  padding: 20px 0;
}
@media (max-width: 992px) {
  .table-wrap ul {
    border-left: none;
    border-right: none;
  }
  .table-wrap ul .item {
    margin-bottom: 6px;
  }
  .table-wrap ul .item:nth-last-of-type(1) {
    margin-bottom: 0;
  }
  .table-wrap ul .action-wrap {
    text-align: right;
  }
}
@media (min-width: 992px) {
  .table-wrap ul .timeBar .date {
    padding-left: 60px;
  }
}
.lists-account {
  margin-bottom: 32px;
  border: 1px solid #CDCDCD;
}
.lists-account li {
  padding: 20px;
  border-bottom: 1px solid #CDCDCD;
}
.lists-account li:nth-last-of-type(1) {
  border-bottom: none;
}
.lists-account li .img-wrap {
  display: inline-block;
  min-width: 48px;
  text-align: center;
  margin-right: 32px;
}
.lists-account li .titles {
  font-size: 22px;
  position: relative;
  top: 11px;
}
.lists-account li .btn-wraps-account {
  position: relative;
  top: 2px;
}
.lists-account li .btn-wraps-account .M-btn-42 {
  float: right;
}
@media (max-width: 992px) {
  .lists-account {
    border-left: none;
    border-right: none;
  }
  .lists-account li .btn-wraps-account {
    margin-top: 10px;
    top: 0;
  }
  .lists-account li .btn-wraps-account .M-btn-42 {
    width: 100%;
  }
  .lists-account li .img-wrap {
    margin-right: 16px;
  }
  .lists-account li .titles {
    font-size: 19px;
  }
}
.des-list li {
  margin-bottom: 16px;
}
.des-list li .title,
.des-list li .text {
  display: block;
}
.des-list li .title {
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 6px;
  color: #777777;
}
.des-list li .text {
  font-size: 16px;
}
.block-line {
  padding: 20px;
  border: 1px solid #CDCDCD;
}
@media (max-width: 992px) {
  .block-line {
    border-left: none;
    border-right: none;
  }
}
.card-no-header {
  border: 1px solid #CDCDCD;
  padding: 32px;
}
@media (max-width: 992px) {
  .card-no-header {
    padding: 20px;
  }
}
@media (max-width: 992px) {
  .news-detail-header {
    padding: 16px 20px 0 20px;
  }
}
.btn-group {
  text-align: center;
}
.ques-label {
  display: inline-block;
  font-weight: 500;
  font-size: 16px;
  color: #777777;
  margin-bottom: 4px;
}
.getCodeItem {
  position: relative;
}
.getCodeItem .getCodeButton {
  position: absolute;
  top: 26px;
  right: 0;
  border-bottom: 1px solid #FFFFFF;
}
.getCodeItem .getCodeButton .btn-warning {
  min-width: 82px;
  text-align: center;
}
.localLang img {
  width: 20px;
}
.localLang .border {
  border: 1px solid #ccc;
}
.localLang span {
  position: relative;
  top: -3px;
  margin-left: 10px;
}
.font-size-16 {
  font-size: 16px !important;
}
.font-size-18 {
  font-size: 18px !important;
}
.font-size-22 {
  font-size: 22px !important;
}
.font-weight-500 {
  font-weight: 500;
}
.font-weight-400 {
  font-weight: 400;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-color {
  color: #000000;
}
.link {
  cursor: pointer;
}
.link:hover {
  color: #1681E5;
}
.max-width-800 {
  max-width: 800px;
  margin: 0 auto;
}
.min-width-180 {
  min-width: 180px !important;
}
.top-12 {
  position: relative;
  top: 12px;
}
.pa-32 {
  padding: 32px !important;
}
.line-24 {
  line-height: 24px;
}
.disabled {
  cursor: not-allowed;
}
/* CDN 服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
@font-face {
  font-family: 'iconfont';
  /* Project id 2017500 */
  src: url('//at.alicdn.com/t/font_2017500_2p3ijp5hghs.woff2?t=1622167293646') format('woff2'), url('//at.alicdn.com/t/font_2017500_2p3ijp5hghs.woff?t=1622167293646') format('woff'), url('//at.alicdn.com/t/font_2017500_2p3ijp5hghs.ttf?t=1622167293646') format('truetype');
}
.M-form .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
  display: none;
}
.M-form .ant-form-item-label > label {
  color: #000000;
  font-family: "Montserrat", Hiragino Kaku Gothic Pro, Meiryo, sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.M-form .ant-input,
.M-form .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: none;
}
.M-form .ant-form-item-control-input-content {
  border-bottom: 1px solid #000;
}
.M-form .ant-form-item {
  color: #000000;
}
.M-form .ant-form-item.no-border-b .ant-form-item-control-input-content {
  border-bottom: none;
}
.M-form .ant-input-focused,
.M-form .ant-input:focus {
  border: none;
  box-shadow: none;
}
.M-form .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: none !important;
  box-shadow: none !important;
}
.M-form .ant-select-arrow {
  font-size: 16px;
  color: #777777;
}
.M-form .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.M-form .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  border: none;
}
.M-form .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused,
.M-form .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus {
  box-shadow: none;
}
.M-form .ant-input-affix-wrapper {
  border: none;
}
.M-form .ant-input-affix-wrapper-focused,
.M-form .ant-input-affix-wrapper:focus {
  box-shadow: none;
}
.M-form .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.M-form .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  border: none;
  box-shadow: none;
}
.M-form .ant-checkbox-wrapper {
  color: #000000;
  font-family: "Montserrat", Hiragino Kaku Gothic Pro, Meiryo, sans-serif;
}
.M-form .ant-input:placeholder-shown {
  color: red;
}
.M-form input::placeholder {
  font-family: "Montserrat", Hiragino Kaku Gothic Pro, Meiryo, sans-serif;
  color: #777777;
}
.M-form .ant-radio {
  color: #000000;
}
.M-form .ant-radio .ant-radio-inner {
  border: 2px solid #777777;
}
.M-form .M-ques-radio .ant-form-item-control {
  padding-left: 16px;
}
.M-form .M-ques-radio label {
  font-size: 18px;
  font-weight: 400;
  color: #000000;
}
.M-form .M-ques-radio .ant-form-item-label {
  padding-bottom: 14px;
}
.M-form .M-ques-radio .ant-form-item-label > label {
  font-weight: 500;
}
.M-form .M-ques-radio .ant-checkbox-inner {
  border: 2px solid #777777;
}
.M-btn-warp {
  padding-top: 20px;
  padding-bottom: 10px;
}
.M-btn.ant-btn {
  border-radius: 5px;
  height: 52px;
  font-size: 18px;
  font-family: "Montserrat", Hiragino Kaku Gothic Pro, Meiryo, sans-serif;
}
.M-dropdown-navbar .ant-space-item {
  color: #000000;
  font-family: "Montserrat", Hiragino Kaku Gothic Pro, Meiryo, sans-serif;
  font-size: 16px;
}
.M-dropdown-navbar .ant-space-item .anticon-down {
  color: #777777;
}
.M-dropdown-navbar .ant-dropdown-menu {
  margin-top: 26px;
  border-radius: 10px;
  width: 220px;
  background: #ffffff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border: 1px solid #CDCDCD;
}
.M-pagination .ant-pagination-item {
  min-width: 24px;
  border: 1px solid #cdcdcd;
  border-radius: 0;
}
.M-pagination .ant-pagination-item,
.M-pagination .ant-pagination-total-text {
  height: 24px;
}
.M-pagination .ant-pagination-item,
.M-pagination .ant-pagination-total-text {
  line-height: 22px;
}
.M-pagination .ant-pagination-item a {
  font-family: "Montserrat", Hiragino Kaku Gothic Pro, Meiryo, sans-serif;
  font-size: 16px;
  font-weight: 500;
}
.M-pagination .ant-pagination-jump-next,
.M-pagination .ant-pagination-jump-prev,
.M-pagination .ant-pagination-next,
.M-pagination .ant-pagination-prev {
  height: 24px;
  min-width: 24px;
  font-family: "Montserrat", Hiragino Kaku Gothic Pro, Meiryo, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
}
.M-pagination .ant-pagination-disabled .ant-pagination-item-link,
.M-pagination .ant-pagination-disabled:hover .ant-pagination-item-link {
  border: none;
}
.M-pagination .ant-pagination-next .ant-pagination-item-link,
.M-pagination .ant-pagination-prev .ant-pagination-item-link {
  border: none;
  color: #777777;
  font-family: "Montserrat", Hiragino Kaku Gothic Pro, Meiryo, sans-serif;
  font-size: 16px;
  font-weight: 500;
}
.M-pagination .ant-pagination-item-active {
  background-color: #f1f1f1;
  border: 1px solid #cdcdcd;
}
.M-pagination .ant-pagination-item-active a {
  color: #000000;
}
.M-pagination .ant-select-selection-item {
  font-family: "Montserrat", Hiragino Kaku Gothic Pro, Meiryo, sans-serif;
  font-size: 16px;
  font-weight: 500;
}
.M-pagination .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid #CDCDCD;
}
.M-btn-42.ant-btn {
  border-radius: 5px;
  height: 42px;
  font-size: 16px;
  padding: 4px 20px;
}
.M-des .ant-descriptions-item-label:after {
  display: none;
}
.M-des .ant-descriptions-item-label {
  font-size: 13px;
  color: #777777;
}
.M-des .ant-descriptions-item-content {
  font-size: 16px;
  color: #000000;
}
.M-des .ant-descriptions-row > th {
  padding-bottom: 6px;
}
.M-des .ant-descriptions-row > td {
  padding-bottom: 16px;
}
.M-modal.ant-modal {
  color: #000000;
  font-weight: 400;
}
.M-modal .ant-modal-header {
  border-radius: 5px 5px 0 0;
}
.M-modal .ant-modal-content {
  border-radius: 5px;
}
.M-modal .ant-btn {
  font-size: 15px;
  border-radius: 5px;
  color: #ffffff;
  height: 35px;
}
.M-modal .ant-btn.ant-btn-default {
  background-color: #6C757D;
}
.M-modal .ant-modal-title {
  font-size: 18px;
  font-weight: 500;
}
.M-modal .ant-modal-body {
  padding: 20px;
}
.M-modal .ant-modal-close {
  color: #000000;
}
.capitalize-form-item .ant-form-item-label > label {
  text-transform: capitalize;
}
.M-upload .ant-upload.ant-upload-select-picture-card {
  width: 100%;
  height: 120px;
  background-color: #FFFFFF;
  border: 1px solid #CDCDCD;
  border-radius: 0;
  margin-bottom: 0;
}
.M-upload .ant-upload {
  font-size: 16px;
  color: #000000;
}
.M-mobile-menu-drawer .ant-drawer-body {
  padding: 16px 0;
}
/* CDN 服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
@font-face {
  font-family: 'iconfont';
  /* Project id 2017500 */
  src: url('//at.alicdn.com/t/font_2017500_2p3ijp5hghs.woff2?t=1622167293646') format('woff2'), url('//at.alicdn.com/t/font_2017500_2p3ijp5hghs.woff?t=1622167293646') format('woff'), url('//at.alicdn.com/t/font_2017500_2p3ijp5hghs.ttf?t=1622167293646') format('truetype');
}
