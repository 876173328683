.upload {
  .box {
    height: 120px;
    padding-top: 26px;
    background: #FFFFFF;
    border: 1px solid #CDCDCD;
    position: relative;
    text-align: center;

    input {
      cursor: pointer;
      opacity: 0;
      outline: none;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }

    p {
      margin-top: 6px;
      font-size: 16px;
      font-weight: 500;
    }

    .img {
      max-width: 100%;
      max-height: 90px;
    }
  }
}

.imgPreview {
  max-width: 100%;
  max-height: 90px;
}

.imgPreviewWrap {
  img {
    max-width: 100%;
    max-height: 90px;
  }
}
