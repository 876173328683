@import "./color";
/* CDN 服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
@font-face {
  font-family: 'iconfont';  /* Project id 2017500 */
  src: url('//at.alicdn.com/t/font_2017500_2p3ijp5hghs.woff2?t=1622167293646') format('woff2'),
  url('//at.alicdn.com/t/font_2017500_2p3ijp5hghs.woff?t=1622167293646') format('woff'),
  url('//at.alicdn.com/t/font_2017500_2p3ijp5hghs.ttf?t=1622167293646') format('truetype');
}

.M-form {
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
    display: none;
  }

  .ant-form-item-label > label {

    color: rgb(0, 0, 0);
    font-family: @font-family;
    font-size: 14px;
    font-weight: 500;
  }

  .ant-input, .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
  }

  .ant-form-item-control-input-content {
    border-bottom: 1px solid #000;
  }

  .ant-form-item {
    color: @text-color;
    &.no-border-b {
      .ant-form-item-control-input-content {
        border-bottom: none;
      }
    }
  }

  .ant-input-focused, .ant-input:focus {
    border: none;
    box-shadow: none;
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none !important;
    box-shadow: none !important;
  }

  .ant-select-arrow {
    font-size: 16px;
    color: @text-color2;
  }

  .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input, .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
    border: none;
  }

  .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused, .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus {
    box-shadow: none;
  }

  .ant-input-affix-wrapper {
    border: none;
  }

  .ant-input-affix-wrapper-focused, .ant-input-affix-wrapper:focus {
    box-shadow: none;
  }

  .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper, .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
    border: none;
    box-shadow: none;
  }

  .ant-checkbox-wrapper {
    color: @text-color;
    font-family: @font-family;
  }

  .ant-input:placeholder-shown {
    color: red;
  }

  input::placeholder {
    font-family: @font-family;
    color: @text-color2;
  }

  .ant-radio {
    color: #000000;

    .ant-radio-inner {
      border: 2px solid @text-color2;
    }
  }

  .M-ques-radio {
    .ant-form-item-control {
      padding-left: 16px;
    }
    label {
      font-size: 18px;
      font-weight: 400;
      color: @text-color;
    }

    .ant-form-item-label {
      padding-bottom: 14px;

      > label {
        font-weight: 500;
      }
    }

    .ant-checkbox-inner {
      border: 2px solid @text-color2;
    }
  }
}

.M-btn-warp {
  padding-top: 20px;
  padding-bottom: 10px;
}

.M-btn {
  &.ant-btn {
    border-radius: 5px;
    height: 52px;
    font-size: 18px;
    font-family: @font-family;
  }
}

.M-dropdown-navbar {
  .ant-space-item {
    color: @text-color;
    font-family: @font-family;
    font-size: 16px;

    .anticon-down {
      color: @text-color2;
    }
  }
}

.M-dropdown-navbar {
  .ant-dropdown-menu {
    margin-top: 26px;
    border-radius: 10px;
    width: 220px;
    background: #ffffff;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    border: 1px solid #CDCDCD;
  }

}


.M-pagination {
  .ant-pagination-item {
    min-width: 24px;
    border: 1px solid #cdcdcd;
    border-radius: 0;
  }

  .ant-pagination-item, .ant-pagination-total-text {
    height: 24px;
  }

  .ant-pagination-item, .ant-pagination-total-text {
    line-height: 22px;
  }

  .ant-pagination-item a {
    font-family: @font-family;
    font-size: 16px;
    font-weight: 500;
  }

  .ant-pagination-jump-next, .ant-pagination-jump-prev, .ant-pagination-next, .ant-pagination-prev {
    height: 24px;
    min-width: 24px;
    font-family: @font-family;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
  }

  .ant-pagination-disabled .ant-pagination-item-link, .ant-pagination-disabled:hover .ant-pagination-item-link {
    border: none;
  }

  .ant-pagination-next .ant-pagination-item-link, .ant-pagination-prev .ant-pagination-item-link {
    border: none;
    color: @text-color2;
    font-family: @font-family;
    font-size: 16px;
    font-weight: 500;
  }

  .ant-pagination-item-active {
    background-color: #f1f1f1;
    border: 1px solid #cdcdcd;

    a {
      color: @text-color;
    }
  }

  .ant-select-selection-item {
    font-family: @font-family;
    font-size: 16px;
    font-weight: 500;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid #CDCDCD;
  }
}

.M-btn-42 {
  &.ant-btn {
    border-radius: 5px;
    height: 42px;
    font-size: 16px;
    padding: 4px 20px;
  }
}

.M-des {
  .ant-descriptions-item-label:after {
    display: none;
  }

  .ant-descriptions-item-label {
    font-size: 13px;
    color: @text-color2;
  }

  .ant-descriptions-item-content {
    font-size: 16px;
    color: @text-color;
  }

  .ant-descriptions-row > th {
    padding-bottom: 6px;
  }

  .ant-descriptions-row > td {
    padding-bottom: 16px;
  }
}

.M-modal {

  &.ant-modal {
    color: @text-color;
    font-weight: 400;
  }

  .ant-modal-header {
    border-radius: 5px 5px 0 0;
  }

  .ant-modal-content {
    border-radius: 5px;
  }

  .ant-btn {
    font-size: 15px;
    border-radius: 5px;
    color: #ffffff;
    height: 35px;

    &.ant-btn-default {
      background-color: #6C757D;
    }
  }

  .ant-modal-title {
    font-size: 18px;
    font-weight: 500;
  }

  .ant-modal-body {
    padding: 20px;
  }

  .ant-modal-close {
    color: @text-color;
  }
}

.capitalize-form-item {
  .ant-form-item-label {
    > label {
      text-transform: capitalize;
    }
  }
}

.M-upload {
  .ant-upload.ant-upload-select-picture-card {
    width: 100%;
    height: 120px;
    background-color: #FFFFFF;
    border: 1px solid #CDCDCD;
    border-radius: 0;
    margin-bottom: 0;
  }

  .ant-upload {
    font-size: 16px;
    color: @text-color;
  }
}

.M-mobile-menu-drawer {
  .ant-drawer-body {
    padding: 16px 0;
  }
}


