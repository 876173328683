.verificationId {

  .header {
    margin: 10px 0 30px 0;
    font-size: 16px;
  }

  .cardTitle {
    text-align: center;
    > span {
      font-size: 20px;
      font-weight: 500;
      margin-left: 20px;
      position: relative;
      top: 8px;
    }
  }

  .list {
    padding: 26px 0 16px 0;
    li {
      .listTitle {
        display: block;
        font-weight: 700;
        font-size: 16px;
      }
      .des {
        display: block;
        padding-left: 10px;
      }
    }
  }

  .btnGroup {
    margin-top: 40px;
  }
}
