.newsDetail {
  .main {
    margin-top: 30px;
    margin-bottom: 40px;
    border: 1px solid #CDCDCD;

    > .title{
      padding: 20px;
    }

    > .title {
      font-size: 22px;
      border-bottom: 1px solid #CDCDCD;
    }

    .content {
      font-size: 16px;
      padding: 12px 20px 20px 20px;
      .sign {
        text-align: right;
        padding-bottom: 10px;

        span {
          font-size: 16px;
          display: block;
        }
      }

      .text {
        * {
          font-size: 16px;
        }
        //line-height: 30px;

        p {
          //margin-bottom: 20px;
        }
      }
    }
  }
}
