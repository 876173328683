@import "../color";

.login {
  padding-top: 60px;

  .loginBox {
    padding: 0 23px 23px 23px;
    max-width: 500px;
    margin: 0 auto;
    background-color: #fff;
  }
}

.header {
  padding-top: 30px;
  text-align: center;
  position: relative;

  .line {
    max-width: 300px;
    height: 4px;
    margin: 30px auto 0 auto;
    background-color: rgb(241, 241, 241);

    li {
      height: 100%;
      width: 33%;
      background-color: rgb(241, 241, 241);

      &.active {
        background-color: @primary-color;
      }
    }
  }

  .title {
    font-weight: 500;
    font-size: 22px;
    color: @text-color2;
    display: block;
    padding: 12px 20px 20px 20px;
  }

  .localLang {
    position: absolute;
    right: 0;
    top: 38px;
  }
}

.tips {
  font-size: 16px;
  font-family: @font-family;
  color: @text-color;
  font-weight: 400;
  padding-bottom: 20px;
}

@media (max-width: 992px) {
  .tips_pa {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.tips2 {
  position: relative;
  top: -10px;
  font-family: @font-family;
  font-size: 14px;
  font-weight: 500;
  color: @text-color2;
}

.resetTips {
  padding-bottom: 14px;
  font-weight: 500;
  font-size: 16px;
  color: @text-color2;
}

.btnWrap {
  padding-top: 28px;
}

.backWrap {
  padding-top: 6px;
}

.loginTipWrap {
  text-align: center;
  max-width: 500px;
  margin: 24px auto 0 auto;

  .tips {
    color: @text-color2;
    padding-bottom: 0;
  }
}

.agreeWrap {
 padding: 6px 0;
}

.rememberMe {
  position: relative;
  top: -10px;
}

.verificationTip {
  padding: 16px 0 10px 0;
}
