.cards {
  border: 1px solid #CDCDCD;

  > header {
    border-bottom: 1px solid #CDCDCD;
    padding: 20px;
  }

  .main {
    padding: 20px;
  }
}

@media (max-width: 992px) {
  .cards {
    border-left: none;
    border-right: none;
  }
}
