body,
div,
ul,
ol,
li,
h1,
h2,
h3,
h4,
p,
section,
nav,
a,
tr,
th,
td,
input {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 14px;
}


img {
  border: none;
  vertical-align: top;
}
ul{
  list-style: none;
}

a {
  text-decoration: none;
}

i {
  font-style: normal;
}

.clearfix {
  zoom: 1;
}

.clearfix:after {
  content: ".";
  width: 0;
  height: 0;
  visibility: hidden;
  display: block;
  clear: both;
  overflow: hidden;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

table {
  border-collapse: collapse !important;
  /*table-layout: fixed;*/
}
