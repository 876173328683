@import "../color";

.main {
  min-height: calc(100vh - 180px);
}

@media (max-width: 992px) {
  .main {
    padding: 0;
  }
}

@media (min-width: 992px) {
  .main {
    padding: 20px;
  }
}

.footers {
  padding: 20px;
  span, a {
    color: @text-color2;
  }
  a {
    &:hover {
      color: @primary-color;
    }
  }
  .text {
    display: block;
  }
}

@media (min-width: 992px) {
  .footers {
    .itemsWrap {
      text-align: right;
      .items {
        display: inline-block;
        margin-right: 16px;
      }
    }
  }
}

@media (max-width: 992px) {
  .footers {
    .itemsWrap {
      .items {
        margin-top: 10px;
      }
    }
  }
}


