@import '../color';

.mobileMenu {
  > header {
    padding: 20px 16px 16px 16px;

    .close {
      width: 50px;
      height: 50px;
      text-align: right;

    }
  }

  .info {
    border-bottom: 1px solid #CDCDCD;
    padding: 0 16px 16px 16px;

    .userIconWrap {
      margin-left: 0;
      position: relative;
      top: 6px;
    }

    .nameWrap {
      .name {
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 6px;
      }

      .id {
        span {
          font-size: 13px;
          color: @text-color2;
          margin-right: 10px;
        }
      }
    }
  }

  .menuList {
    border-bottom: 1px solid #CDCDCD;
    padding: 6px 0;

    li {
      padding: 10px 16px;

      > span {
        font-size: 18px;
        font-weight: 400;
      }
    }
  }

  .schedule_a_call {
    img {
      position: relative;
      top: 4px;
    }
    span {
      margin-left: 10px;
    }
  }
  .active {
    color: @primary-color;
  }
}
