@import "../color";

.navbar {
  height: 80px;
  padding: 0 20px;
  background-color: #ffffff;
  border-bottom: 1px solid #CDCDCD;

  .logo {
    padding-top: 24px;
  }

  .menu {
    height: 80px;

    li {
      cursor: pointer;
      height: 100%;
      line-height: 80px;
      display: inline-block;
      padding: 0 8px;
      font-size: 18px;

      &:hover {
        color: @primary-color;
      }

      &.active {
        color: @primary-color;
      }
    }
  }

  .call {
    display: inline-block;
    height: 36px;
    background: #F1F1F1;
    line-height: 36px;
    padding: 0 20px 0 16px;
    position: relative;
    top: 20px;
    cursor: pointer;

    img {
      width: 18px;
      position: relative;
      top: 8px;
    }

    span {
      margin-left: 16px;
      font-size: 16px;
      font-weight: 400;
      color: @primary-color;
    }
  }

  .dropdown {
    position: relative;
    top: 26px;
  }

  .mobile_menu_icon {
    cursor: pointer;
    width: 50%;
    color: @text-color2;
    height: 80px;
    line-height: 86px;
  }
}

@media (max-width: 992px) {
  .navbar {
    .logo {
      padding-top: 30px;
    }
  }
}

.dropdownItem {
  padding: 12px;

  img {
    width: 20px;
    position: relative;
    top: 2px;
  }

  span {
    font-size: 16px;
    margin-left: 16px;
  }
}

