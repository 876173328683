.errorMessage {
  margin-bottom: 28px;
  border: 1px solid #CDCDCD;
  position: relative;
  color: #DC143C;
  padding: 6px 26px;

  &:before {
    position: absolute;
    left: 0;
    top: 0;
    content: '';
    width: 10px;
    height: 100%;
    background: #DC143C;
  }
}
